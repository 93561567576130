import React, { createContext, useState, useContext, useEffect } from 'react';

// Define initial context values
const initialUserState = {
    token: null,
    isAuthenticated: false,
    isLoading: true,
    telegramUsername: null, // Add this line
};

const UserContext = createContext(initialUserState);

export const UserProvider = ({ children }) => {
    const [userState, setUserState] = useState(initialUserState);

    const login = (token) => {
        console.log('Login Token: ', token);
        localStorage.setItem('token', token);
        setUserState({ token, isAuthenticated: true, isLoading: false });
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUserState({ token: null, isAuthenticated: false, isLoading: false });
    };

    const setTelegramUsername = (data) => {
        setUserState(prevState => ({ ...prevState, telegramUsername: data }));
    };

    const checkToken = async () => {
        const token = localStorage.getItem('token');

        if (token) {
            setUserState({
                token,
                isAuthenticated: true,
                isLoading: false,
            });
        } else {
            setUserState({
                ...initialUserState,
                isLoading: false,
            });
        }
    };

    useEffect(() => {
        checkToken(); // Run once on mount
        const interval = setInterval(checkToken, 1000);
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <UserContext.Provider value={{ ...userState, login, logout, setTelegramUsername }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);

export const useLogin = () => {
    const { login } = useContext(UserContext);
    return login;
};

export const useLogout = () => {
    const { logout } = useContext(UserContext);
    return logout;
};

export const useTelegramUsername = () => {
    const { telegramUsername, setTelegramUsername } = useContext(UserContext);
    return { telegramUsername, setTelegramUsername };
};
