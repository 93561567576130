import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";
import Phaser from 'phaser';
import BettingScene from '../scenes/BettingScene';
import ResultScene from '../scenes/ResultScene';  // Fixed import

const Play = () => {
  const { token } = useUser();
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  const gameRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }

    // Fetch dashboard data
    const fetchDashboard = async () => {
      try {
        const response = await axios.get('https://casino-api.mydreamstudio.workers.dev/me/dashboard', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log(response.data);
        setUserId(response.data.id);
      } catch (err) {
        setError("Failed to fetch dashboard data");
        console.error(err);
      }
    };

    fetchDashboard();
  }, [token, navigate]);

  useEffect(() => {
    if (userId) {
      const config = {
        type: Phaser.AUTO,
        width: window.innerWidth,
        height: window.innerHeight,
        parent: 'phaser-game',
        scene: [BettingScene, ResultScene],
        scale: {
          mode: Phaser.Scale.RESIZE,
          autoCenter: Phaser.Scale.CENTER_BOTH
        }
      };

      const game = new Phaser.Game(config);
      gameRef.current = game;

      const ws = new WebSocket(`wss://casino-api.mydreamstudio.workers.dev/baccarat/${userId}/socket`);

      ws.onopen = () => {
        console.log('WebSocket connected');
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleServerMessage(data, game);
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      game.ws = ws;

      // Clean up
      return () => {
        ws.close();
        if (game) game.destroy(true);
      };
    }
  }, [userId]);

  function handleServerMessage(data, game) {
    const currentScene = game.scene.getScene(game.scene.active);
    if (currentScene) {
      switch (data.type) {
        case 'gameState':
          currentScene.updateGameState(data.state);
          break;
        case 'result':
          game.scene.start('ResultScene', { result: data.result });
          break;
        case 'balance':
          currentScene.updateBalance(data.balance);
          break;
        // Add more cases as needed
      }
    }
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="min-h-screen p-0 m-0">
      <div id="phaser-game" className="w-full h-full"></div>
    </div>
  );
};

export default Play;