import Phaser from 'phaser';

export default class ResultScene extends Phaser.Scene {
  constructor() {
    super('ResultScene');
  }

  preload() {
    this.load.spritesheet('cards', 'assets/Cards.png', { frameWidth: 140, frameHeight: 190 });
  }

  create(data) {
    const { width, height } = this.sys.game.config;

    this.playerScoreText = this.add.text(width * 0.3, height * 0.3, 'Player: 0', { fontSize: '24px', fill: '#fff' });
    this.bankerScoreText = this.add.text(width * 0.7, height * 0.3, 'Banker: 0', { fontSize: '24px', fill: '#fff' });
    this.resultText = this.add.text(width * 0.5, height * 0.1, data.result, { fontSize: '32px', fill: '#fff' }).setOrigin(0.5);

    this.playerCardArea = this.add.container(width * 0.3, height * 0.5);
    this.bankerCardArea = this.add.container(width * 0.7, height * 0.5);

    this.newRoundButton = this.add.text(width * 0.5, height * 0.9, 'NEW ROUND', { fontSize: '32px', fill: '#fff' })
      .setOrigin(0.5)
      .setInteractive()
      .on('pointerdown', () => this.startNewRound());
  }

  updateGameState(state) {
    this.playerScoreText.setText(`Player: ${state.playerScore}`);
    this.bankerScoreText.setText(`Banker: ${state.bankerScore}`);
    this.updateCardDisplay(state.playerCards, state.bankerCards);
  }

  updateCardDisplay(playerCards, bankerCards) {
    this.playerCardArea.removeAll(true);
    this.bankerCardArea.removeAll(true);

    playerCards.forEach((card, index) => {
      const cardSprite = this.add.sprite(index * 50, 0, 'cards', this.getCardFrame(card));
      this.playerCardArea.add(cardSprite);
    });

    bankerCards.forEach((card, index) => {
      const cardSprite = this.add.sprite(index * 50, 0, 'cards', this.getCardFrame(card));
      this.bankerCardArea.add(cardSprite);
    });
  }

  getCardFrame(card) {
    // Implement based on your spritesheet layout
  }

  startNewRound() {
    this.scene.start('BettingScene');
  }
}