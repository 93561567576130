import Phaser from 'phaser';

export default class BettingScene extends Phaser.Scene {
  constructor() {
    super('BettingScene');
  }

  preload() {
    this.load.image('table', 'assets/BaccaratTable.png');
    this.load.image('chip', 'assets/Chip.png');
  }

  create() {
    const { width, height } = this.sys.game.config;

    this.add.image(width / 2, height / 2, 'table').setDisplaySize(width, height);

    this.playerBet = this.add.rectangle(width * 0.3, height * 0.7, 150, 100, 0x00ff00, 0.5).setInteractive();
    this.tieBet = this.add.rectangle(width * 0.5, height * 0.7, 150, 100, 0xff0000, 0.5).setInteractive();
    this.bankerBet = this.add.rectangle(width * 0.7, height * 0.7, 150, 100, 0x0000ff, 0.5).setInteractive();

    this.playerBet.on('pointerdown', () => this.placeBet('player'));
    this.tieBet.on('pointerdown', () => this.placeBet('tie'));
    this.bankerBet.on('pointerdown', () => this.placeBet('banker'));

    this.dealButton = this.add.text(width * 0.5, height * 0.9, 'DEAL', { fontSize: '32px', fill: '#fff' })
      .setOrigin(0.5)
      .setInteractive()
      .on('pointerdown', () => this.dealHand());

    this.balanceText = this.add.text(width * 0.1, height * 0.05, 'Balance: $0', { fontSize: '24px', fill: '#fff' });
  }

  placeBet(type) {
    this[`${type}Bet`].setFillStyle(0xffff00, 0.8);

    console.log('placeBet', type);
    //this.game.ws.send(JSON.stringify({ action: 'placeBet', type }));
  }

  dealHand() {
    console.log('dealHand');
    //this.game.ws.send(JSON.stringify({ action: 'deal' }));
  }

  updateBalance(balance) {
    const balance1 = 1000;
    this.balanceText.setText(`Balance: $${balance1}`);
    //this.balanceText.setText(`Balance: $${balance}`);
  }
}