import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser, login } from '../UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { startAuthentication } from '@simplewebauthn/browser';

const Telegram = () => {        
    const [startApp, setStartApp] = useState(null);
    const { token, login } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Expand the window to fit the screen
        const tgWebApp = window.Telegram?.WebApp;
        if (tgWebApp) {
            console.log(`is Telegram Web App`);
            tgWebApp.expand();
        }

        // Get Telegram Web App initialization data
        const initDataString = tgWebApp?.initData || '';
        console.log(`initDataString: ${initDataString}`);
        telegramLogin(initDataString);


    }, [location]);

    const telegramLogin = async (initDataString) => {
        try {
            const response = await axios.post('https://casino-api.mydreamstudio.workers.dev/auth/telegram', { initData: initDataString });
            console.log(response.data);
            // Uncomment the following lines when ready to implement login and navigation
            login(response.data);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error logging in with Telegram:', error);
        }
    }

    return (
        <>
            <h1 className="text-2xl font-bold mb-4">Telegram Web App</h1>



            {/* Your existing JSX */}
        </>
    );
};

export default Telegram;