import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { UserProvider } from './UserContext';


import Dashboard from './pages/Dashboard';
import Telegram from './pages/Telegram';
import Play from './pages/Play';

const App = () => {

  useEffect(() => {

    const tgWebApp = window.Telegram?.WebApp;
    if (tgWebApp) {
        console.log(`is Telegram Web App: App.js`);
        tgWebApp.expand();
    }
    
  
  }, []);

  const authenticateUser = () => {
    const token = localStorage.getItem('token');
    if(token) { return true; } else { return false; }
  };

  const PrivateRoute = ({ children }) => {
    return authenticateUser() ? (
      <>        
          {children}        
        {/* <nav className="fixed bottom-0 left-0 right-0 bg-background border-t z-10">
          <div className="container mx-auto px-6 py-4">
            <ul className="flex justify-between items-center">
              <li><Button variant="ghost" asChild size="lg"><Link to="/dashboard"><HomeIcon size={28} /></Link></Button></li>
            </ul>
          </div>
        </nav> */}
      </>
    ) : <Navigate to="/tma" />;
  };
  return (
      <UserProvider>
      <Router>
        <div className="min-h-screen bg-background text-foreground">
          <Routes>
            <Route path="/" element={authenticateUser() ? <Navigate to="/dashboard" /> : <Telegram />} />
            <Route path="/tma" element={<Telegram />} />
            
            {/* Wrap authenticated routes with PrivateRoute */}
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/play" element={<PrivateRoute><Play /></PrivateRoute>} />
          </Routes>
        </div>
      </Router>
      </UserProvider>
  );
};

export default App;
