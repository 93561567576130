import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUser, useTelegramUsername } from "../UserContext";
import Phaser from 'phaser';

const Dashboard = () => {
  const { token } = useUser();
  
  const { telegramUsername, setTelegramUsername } = useTelegramUsername();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const gameRef = useRef(null);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      fetchDashboardData();
    }
  }, [token, navigate]);

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(
        "https://casino-api.mydreamstudio.workers.dev/me/dashboard",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setTelegramUsername(response.data.telegramUsername);
    } catch (err) {
      setError("Failed to fetch dashboard data");
      console.error(err);
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="min-h-screen p-2">
      {/* <button onClick={handleLogout}>Logout</button> */}
      
      <Link to="/play">Play</Link>
      {/* <Link to="/team">Team</Link>
      <Link to="/bank">Bank</Link>
      <Link to="/user">User</Link> */}

    </div>
  );
};

export default Dashboard;